<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">
        VFD Transaction Query
      </h1>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <div class="w-full p-5 pb-10">
            <div>
              <div class="flex flex-wrap xl:-mx-2">
                <div class="w-full xl:my-2 xl:px-2">
                  <!-- Column Content -->
                  <p class="text-sm font-bold mb-3"></p>
                  <div class="">
                    <span class="mr-2">Account number:</span>
                    <input
                      type="text"
                      class="inline-block p-2 mr-2 ring-1 outline-none ring-gray-300 rounded-sm text-sm"
                      name=""
                      placeholder="Enter Business Account Number"
                      id=""
                      v-model="businessAcctNumber"
                      required
                    />

                    <span class="mr-2">From:</span>
                    <input
                      type="date"
                      class="inline-block border-2 p-2 rounded-sm mr-2"
                      name=""
                      id=""
                      v-model="dateData.startDate"
                    />

                    <span class="mr-2">To: </span>
                    <input
                      type="date"
                      class="inline-block border-2 p-2 rounded-sm mr-2"
                      name=""
                      id=""
                      v-model="dateData.endDate"
                    />

                    <button
                      type="button"
                      class="py-2 px-6 bg-blue-800 mr-2 rounded-md font-semibold text-white text-sm"
                      @click="handleVFDSearch"
                    >
                      Search
                    </button>
                    <button
                      class="py-2 px-6 bg-red-800 mx-2 rounded-md font-semibold text-white text-sm"
                      @click="clearRecord"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <p v-if="errorMssg" class="mt-3 text-red-600 text-xs">
                {{ errorMssg }}
              </p>
            </div>
          </div>
          <div class="mt-14" style="min-height: 600px" v-if="rows.length > 0">
            <table class="shadow-lg w-full relative border-collapse">
              <thead>
                <tr>
                  <th
                    class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
                  >
                    ID
                  </th>
                  <th
                    class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
                  >
                    Payment Type
                  </th>
                  <th
                    class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
                  >
                    Amount Number
                  </th>
                  <th
                    class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
                  >
                    Routing Code
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Receipt Number
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Bank Number
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Remarks
                  </th>
                  <th
                    class="text-black text-sm border whitespace-normal text-left px-8 py-2"
                  >
                    Transacion Brand Name
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Amount
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Reversed
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Submitted On Date
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Note
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in rows" :key="i">
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.id || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.paymentType || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.accountNumber || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.routingCode || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.receiptNumber || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.bankNumber || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.remarks || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.transactionBrandName || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.amount || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.reversed || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.submittedOnDate || "N/A" }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.note || "N/A" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      businessAcctNumber: "",
      errorMssg: false,
      successMssg: false,
      promptModal: false,
      rows: [],
      dateData: null,
    };
  },
  computed: {
    ...mapGetters(["GET_WALLET_BUSINESSES"]),
    filterBusinesses() {
      return this.GET_WALLET_BUSINESSES.data
        ? this.GET_WALLET_BUSINESSES.data
        : undefined;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    // this.clearRecord();
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.handleVFDSearch();
  },
  methods: {
    async handleVFDSearch() {
      this.isLoading = true;
      let payload = {
        date: this.dateData,
        accountNumber: this.businessAcctNumber,
      };
      let res = await this.$store.dispatch("FETCH_VFD_TRANSACTIONS", payload);

      if (res.data.length > 0) {
        this.rows = res.data;
      } else {
        if (res.data.length === 0) {
          alert("No records found");
        } else {
          alert(res.message);
        }
      }

      this.isLoading = false;
    },

    clearRecord() {
      this.businessAcctNumber = "";
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(222, 230, 242) none repeat scroll 0% 0%;
  /* border-top: 1px solid rgb(0, 9, 61); */
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
